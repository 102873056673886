// react
import React, { Component } from "react";

// third-party
import PropTypes from "prop-types";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { ScrollContext } from "react-router-scroll-4";

// application
import languages from "../i18n";
import { localeChange } from "../store/locale";
import ErrorBoundary from "./ErrorBoundry";
// pages
import Layout from "./Layout";
import HomeLayout from "./HomeLayout";
// import HomePageOne from './industry/home/HomePageOne';
import HomePageTwo from "./industry/home/HomePageTwo";
import Login from "./home/Login";
import HomeMain from "./home/HomeMain";
import MainHomePage from "./home/Mainhome1";
import BlockLoader from "./industry/blocks/BlockLoader";
import Mainhome2 from "./home/Mainhome2";
import Mainhome3 from "./home/Mainhome3";

class Root extends Component {
    componentDidMount() {
        // preloader
        setTimeout(() => {
            const preloader = document.querySelector(".site-preloader");

            preloader&& preloader.addEventListener("transitionend", (event) => {
                if (event.propertyName === "opacity") {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader&& preloader.classList.add("site-preloader__fade");
        }, 500);

        // this is for demo only, you can delete it
        const { localeChange: changeLocale } = this.props;
        const direction = new URLSearchParams(window.location.search).get("dir");

        if (direction !== null) {
            changeLocale(direction === "rtl" ? "ar" : "en");
        }
    }

    shouldUpdateScroll = (prevRouterProps, { location }) =>
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname;

    render() {
        const { locale } = this.props;
        const { messages, direction } = languages[locale];

        return (
            <ErrorBoundary>
                <IntlProvider locale={locale} messages={messages}>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <HelmetProvider>
                            <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                            <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                                <Switch>


                                <Route
                                        path="/vendorregistration"
                                        component={() => {
                                            window.location.href = "https://zfrmz.in/ogD3bDfk0m9N8KSLCLvI";
                                            return <BlockLoader />;
                                        }}
                                    />
                                    <Route
                                        path="/DC"
                                        component={() => {
                                            window.location.href = "https://zfrmz.in/xQE9Sb3FByqIL7agNoOe";
                                            return <BlockLoader />;
                                        }}
                                    />
                                    <Route
                                        path="/auditform"
                                        component={() => {
                                            window.location.href = "https://zfrmz.in/8vox2A0OITS5XRZ8DdCE";
                                            return <BlockLoader />;
                                        }}
                                    />

                                    <Route
                                        path="/catering-services-in-chennai/"
                                        component={() => {
                                            window.location.href = "https://www.hogist.com/catering-services-in-chennai/";
                                            return <BlockLoader />;
                                        }}
                                    />
                                     <Route
                                        path="/industrial-catering-services-near-me/"
                                        component={() => {
                                            window.location.href = "https://www.hogist.com/industrial-catering-services-near-me/";
                                            return <BlockLoader />;
                                        }}
                                    />
                                     <Route
                                        path="/corporate-catering-services-in-chennai/"
                                        component={() => {
                                            window.location.href = "https://www.hogist.com/corporate-catering-services-in-chennai/";
                                            return <BlockLoader />;
                                        }}
                                    />
                                     <Route
                                        path="/order-now/"
                                        component={() => {
                                            window.location.href = "https://www.hogist.com/order-now/";
                                            return <BlockLoader />;
                                        }}
                                    />
                                     <Route
                                        path="/contactless-cafeteria-setup/"
                                        component={() => {
                                            window.location.href = "https://www.hogist.com/contactless-cafeteria-setup/";
                                            return <BlockLoader />;
                                        }}
                                    />
                                     <Route
                                        path="/blog/"
                                        component={() => {
                                            window.location.href = "https://www.hogist.com/blog/";
                                            return <BlockLoader />;
                                        }}
                                    />
                                    <Route
                                        path="/hogist-menu-bank/"
                                        component={() => {
                                            window.location.href = "https://www.hogist.com/hogist-menu-bank/";
                                            return <BlockLoader />;
                                        }}
                                    />
                                      <Route
                                        path="/bulk-food-order/"
                                        component={() => {
                                            window.location.href = "https://www.hogist.com/bulk-food-order/";
                                            return <BlockLoader />;
                                        }}
                                    />
                                   



                                    <Route
                                        path="/"
                                        render={(props) => (
                                            // <HomeLayout {...props} headerLayout="default" homeComponent={HomeMain} />
                                            <HomeLayout
                                                {...props}
                                                headerLayout="default"
                                                homeComponent={Mainhome3}
                                            />
                                        )}
                                    />

{/* <Route exact path="/" component={Mainhome3} /> */}
                                    
                                    {/* <ChomeLayout exact path="/corporate/home" component={CorporateHomePage} /> */}
                                    {/* <Route
                                    path="/corporate/home"
                                    render={(props) => (
                                        <ChomeLayout {...props} headerLayout="compact" homeComponent={CorporateHomePage} />
                                    )}
                                /> */}
                                    {/* <Route
                                    path="/home"
                                    render={(props) => (
                                        <HomeLayout {...props} headerLayout="compact" homeComponent={HomePageTwo} />
                                    )}
                                />
                                <Route
                                    path="/"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Login} />
                                    )}
                                /> */}
                                  

                                    <Redirect to="/" />
                                </Switch>
                            </ScrollContext>
                        </HelmetProvider>
                    </BrowserRouter>
                </IntlProvider>
            </ErrorBoundary>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
